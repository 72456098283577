<template>
  <Form
    :model="form"
    :rules="rules"
    @submit="onSubmit"
  >
    <h1 class="text-3xl font-semibold text-center mb-12">
      {{ useT('auth.loginTo') }}
      <span class="text-gray-500">
        {{ useWorkspaceStore()?.name }}
      </span>
    </h1>

    <InputBlock
      :label="useT('poly.email')"
      prop="email"
    >
      <TextInput
        v-model="form.email"
        type="email"
        name="email"
        autocomplete="email"
        :placeholder="useT('auth.forgottenPassword.email.placeholder')"
      />
    </InputBlock>

    <InputBlock
      :label="useT('common.password')"
      prop="password"
    >
      <TextInput
        v-model="form.password"
        type="password"
        :placeholder="useT('common.password')"
        name="password"
        autocomplete="current-password"
      />
    </InputBlock>

    <div class="mt-8">
      <Button
        type="primary"
        native-type="submit"
        size="large"
        :disabled="!isMounted"
        :loading="isSubmitting"
        class="w-full"
      >
        {{ useT('auth.doLogin') }}
      </Button>
    </div>

    <nuxt-link
      :to="`/forgotten-password?email=${form.email}`"
      class="block mt-3"
    >
      <Button
        class="w-full"
        size="large"
      >
        {{ useT('auth.forgottenPassword.title') }}
      </Button>
    </nuxt-link>
  </Form>
</template>

<script setup>
// Refs
const form = ref({
  email: useRoute()?.query?.email || useRuntimeConfig().public.defaultUserEmail || '',
  password: useRuntimeConfig().public.defaultUserPassword || '',
});

const rules = {
  email: [useFormRules().required],
  password: [useFormRules().required],
};

const isSubmitting = ref(false);
const isMounted = useMounted();

// Methods
const onSubmit = async() => {
  isSubmitting.value = true;

  const user = await useAuthStore().login({
    email: form.value.email,
    password: form.value.password,
  });

  isSubmitting.value = false;

  if (user) {
    useToast(useT('auth.loginSuccessful'));

    if (window?.sessionStorage?.getItem?.('redirectedFromPath')) {
      navigateTo(window?.sessionStorage?.getItem?.('redirectedFromPath'));
    } else {
      navigateTo('/');
    }
  }
};
</script>
